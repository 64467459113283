<template>
  <div class="journalismDetails">
    <TopNavBar2 />
    <div class="box">
      <el-breadcrumb separator=">>">
        <el-breadcrumb-item :to="{ path: '/mobile/journalism' }"
          >新闻动态</el-breadcrumb-item
        >
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="journalism_box">
        <h3>{{ data.title }}</h3>
        <div class="type">
          行业新闻<span>{{ data.date }}</span>
        </div>
        <div class="content">{{ data.content }}</div>
      </div>
    </div>

    <Footer2 />
  </div>
</template>

<script>
export default {
  name: "JournalismDetails",
  data() {
    return {
      data: {
        title: "国庆节放假通知",
        date: "2022-09-29",
        content:
          "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
      },
    };
  },
  created() {},
};
</script>

<style scoped lang="scss">
.journalismDetails {
  .box {
    padding: 15px 16px 35px;
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          font-size: 14px;
          font-weight: 200;
          line-height: 30px;
          color: #333;
        }
        .el-breadcrumb__separator {
          font-family: "AlibabaPuHuiTi-Light";
          margin: 0 8px;
        }
      }
    }
    .journalism_box {
      margin-top: 10px;
      h3 {
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        margin: 15px 0 10px;
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        font-size: 16px;
        color: #333333;
      }
      .type {
        text-align: left;
        line-height: 30px;
        color: #333333;
        font-weight: 200;
        font-size: 13px;
        color: #006eff;
        span {
          font-family: "AlibabaPuHuiTi-Light";
          margin-left: 15px;
          color: #666666;
        }
      }
      .content {
        margin-top: 10px;
        text-align: left;
        font-weight: 200;
        letter-spacing: 1px;
        color: #666666;
        line-height: 24px;
        font-size: 14px;
      }
    }
  }
}
</style>
